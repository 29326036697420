import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import HotelInfoSection from "../components/hotelInfoSection/HotelInfoSection";
import AppInfoSection from "../components/appInfoSection/AppInfoSection";
import MemberBenefitSection from "../components/memberBenefitSection/MemberBenefitSection";
import OfferAndEventSection from "../components/offerAndEventSection/OfferAndEventSection";
import Footer from "../components/footer/Footer";
import hotelImage from "../assets/infor-right.png";
import {
    delhiAnnualBenefitData,
    delhiOneTimeBenefitData,
} from "../utils/tableData";
import { delhiOfferCarousalData } from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";

const DelhiHomePage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        //TO RESET STEP ONE
        if (sessionStorage.getItem("userData")) {
            sessionStorage.removeItem("step");
            sessionStorage.removeItem("d_plan");
            sessionStorage.removeItem("otp");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("userGstData");
            sessionStorage.removeItem("flag");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("m_num");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("l_id");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("z_flag");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("gate_data");
            sessionStorage.removeItem("lnk_flg");
        }
    }, []);
    const delhiPropertyTableData = [
        {
            clone: true,
            tableHeading: "Annual Benefits",
            tableData: delhiAnnualBenefitData,
            tableScrollId: "delhi-annual-table-scroll",
            tableId: "delhi-annual-main-table",
        },
        {
            clone: true,
            tableHeading: "One Time Use Benefits",
            tableHead: "Certificates",
            tableData: delhiOneTimeBenefitData,
            tableScrollId: "delhi-oneTime-table-scroll",
            tableId: "delhi-oneTime-main-table",
        },
    ];
    useEffect(() => {
        sessionStorage.setItem("property", "delhi");
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Become a member of Prestige Club at Radisson Blu Plaza Delhi Airport and enjoy exclusive benefits and special offers on dining, stay and more, all year round."
                />
                <title>
                    Prestige Club | Membership of Radisson Blu Plaza Delhi
                    Airport
                </title>
            </Helmet>
            <Header />
            <HeroSection showForm={true} />
            <HotelInfoSection
                content={`Just five minutes from Indira Gandhi International Airport close to all major city attractions, the <strong><a href="https://www.radissonhotels.com/en-us/hotels/radisson-blu-new-delhi-airport" rel="noopener noreferrer" target="_blank">Radisson Blu Plaza Delhi Airport</a></strong> provides delectable dining options and upscale accommodations, offering the best in luxury and comfort. With an array of dining experiences at seven distinct dining outlets, the hotel also includes state of the art business centre, outdoor pool, fitness centre and the 24 hours operational and award winning, R The Spa.`}
                heading="Radisson Blu Plaza Delhi Airport"
                imgSrc={hotelImage}
                imgAlt="Radisson Blu Plaza Delhi Airport facade shot from right"
            />
            <AppInfoSection />
            <MemberBenefitSection
                property="delhi"
                propertyTableData={delhiPropertyTableData}
                buyFlag={true}
            />
            <OfferAndEventSection carousalData={delhiOfferCarousalData} />
            <Footer />
        </>
    );
};

export default DelhiHomePage;
