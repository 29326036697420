import React, { useCallback, useEffect, useState } from "react";
import downArrow from "../../../../assets/dropdown.png";
import countryCodes from "../../../../utils/countryCodes.json";
import { Row, Col, Form } from "react-bootstrap";
import { CountrySelectorDropdown } from "react-international-phone";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom";
import { NUMBER_REGEX } from "../../../../utils/regex";
import gstStateData from "../../../../utils/gstCodeWithState.json";
import { getCall, postCall } from "../../../../utils/AxiosInterseptor";
import {
    FETCH_MEMBER_ACCOUNT_BY_MOBILE,
    RESEND_OTP,
    SEND_OTP,
    VALIDATE_PINCODE,
    VERIFY_MEMBER_DETAILS,
} from "../../../../utils/axiosUtil";
import verifiedIcon from "../../../../assets/otp-verified.svg";
import { encryptDataFunction } from "../../../../utils/utils";
import CustomModal from "../../../custom-modal/CustomModal";
import errorIcon from "../../../../assets/link-invalid.svg";
import { useDispatch } from "react-redux";
import { setPageLoader } from "../../../../redux/loader-slice/loaderSlice";

export const UserPersonalDetails = React.memo(
    ({
        countryData,
        setCountryData,
        userData,
        setUserData,
        userDataValid,
        setUserDataValid,
        setUserGstData,
        setShowGst,
        otp,
        setOtp,
        otpState,
        setOtpState,
        setOtpSentErrorModal,
    }) => {
        const [inputDisabled, setInputDisabled] = useState(false);
        const [otpSent, setOtpSent] = useState({
            sent: false,
            first: false,
            resend: false,
        });
        const [countrySelectShow, setCountrySelectShow] = useState(false);
        const [timer, setTimer] = useState(20);
        const validatePhoneNumber = useCallback(() => {
            if (
                userData?.mobile?.length >= countryData.minLength &&
                userData?.mobile?.length <= countryData.maxLength
            ) {
                return true;
            }
            return false;
        }, [
            countryData.maxLength,
            countryData.minLength,
            userData?.mobile?.length,
        ]);
        const countryCodeSelectionHandler = (e) => {
            setUserData((prev) => ({ ...prev, mobile: "" }));
            setCountrySelectShow(false);
            const country = countryCodes.find((count) => count.iso2 === e.iso2);
            setCountryData({
                isd: e.dialCode,
                iso2: e.iso2,
                minLength: country?.Min ?? 10,
                maxLength: country?.Max ?? 10,
            });
        };
        const userDataChangeHandler = (e) => {
            let { name, value } = e.target;
            if (name === "fName" || name === "lName") {
                if (value[0]) {
                    value = value[0].toUpperCase() + value.slice(1);
                }
            }
            if (name === "mobile") {
                sessionStorage.removeItem("otp");
                setOtp("");
                setOtpState((prev) => ({ ...prev, verified: false }));
                const isNum = NUMBER_REGEX.test(value);
                setOtpSent({ sent: false, first: false, resend: false });
                if (!isNum || value.length > countryData.maxLength) return;
            }
            if (name === "email") {
                value = value.trim();
            }
            setUserDataValid((prev) => ({ ...prev, [name]: true }));
            setUserData((prev) => ({ ...prev, [name]: value }));
        };
        const resendOtpHandler = async () => {
            if (!validatePhoneNumber()) {
                return;
            }
            if (otpSent.resend) {
                return;
            }
            const payload = {
                transactionType: "verify mobile",
                mobile: +`${countryData?.isd}${userData?.mobile}` ?? "",
            };
            const response = await postCall(RESEND_OTP(), payload);
            if (response.msg !== "Success") {
                if (response.errorCode === "TLC-905") {
                    setOtpSentErrorModal((prev) => ({ ...prev, show: true }));
                }
                return;
            }
            setOtpSent((prev) => ({ ...prev, resend: true, sent: false }));
            setOtpState((prev) => ({
                ...prev,
                trId: response?.data?.transactionID,
            }));
        };
        const mobileChangeHandler = (e) => {
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("ott");
            sessionStorage.removeItem("l_id");
            setInputDisabled(false);
            userDataChangeHandler(e);
        };
        const formatTime = () => {
            return timer < 10 ? `0${timer}` : `${timer}`;
        };
        useEffect(() => {
            // Attach event listener to detect clicks outside the country selector dropdown
            const handleClickOutside = (event) => {
                if (!event.target.classList.contains("country-select")) {
                    setCountrySelectShow(false);
                }
            };
            document.addEventListener("click", handleClickOutside);
            // Cleanup function to remove event listener when component unmounts
            return () => {
                document.removeEventListener("click", handleClickOutside);
            };
        }, []);
        useEffect(() => {
            const fetchMemberDetails = async () => {
                const phone = `${countryData.isd}${userData.mobile}`;
                const response = await getCall(
                    FETCH_MEMBER_ACCOUNT_BY_MOBILE(phone)
                );
                if (response.msg !== "Success") {
                    return false;
                }
                sessionStorage.setItem("memberId", response.data.memberId);
                sessionStorage.setItem("sfId", response.data.memberSfid);
                const countryIsd = response.data.countryCode.slice(1);
                const filterPhoneNumber = response.data.mobile.slice(
                    countryIsd.length
                );
                setUserData({
                    salutation: response?.data?.Salutation ?? "",
                    fName: response?.data?.FirstName ?? "",
                    lName: response?.data?.LastName ?? "",
                    mobile: filterPhoneNumber ?? "",
                    email: response?.data?.email ?? "",
                });
                setUserDataValid({
                    salutation: true,
                    fName: true,
                    lName: true,
                    mobile: true,
                    email: true,
                });
                setInputDisabled(true);
                if (response.data["GST address"].length !== 0) {
                    const gstData = response.data["GST address"][0];
                    setUserGstData({
                        id: gstData.sfid,
                        gstNum: gstData.GSTNumber,
                        cName: gstData.GSTCompanyName,
                        address1: gstData.GSTAddressLine1,
                        address2: gstData.GSTAddressLine2,
                        state: gstData.GSTState,
                        pinCode: gstData.GSTPinCode,
                        country: "India",
                    });
                    setShowGst(true);
                }
            };
            if (
                userData.mobile.length === countryData.maxLength &&
                otpState.verified
            ) {
                fetchMemberDetails();
            }
        }, [
            countryData.isd,
            countryData.maxLength,
            otpState.verified,
            setShowGst,
            setUserData,
            setUserDataValid,
            setUserGstData,
            userData.mobile,
        ]);
        useEffect(() => {
            let interval;
            if (otpSent.sent && timer > 0) {
                interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                }, 1000);
            } else if (timer === 0 && otpSent.sent) {
                setOtpSent((prev) => ({ ...prev, resend: false }));
                clearInterval(interval);
            } else {
                clearInterval(interval);
                setTimer(20);
                setOtpSent((prev) => ({ ...prev, sent: true }));
            }
            return () => clearInterval(interval);
        }, [otpSent.sent, setOtpState, timer]);
        useEffect(() => {
            const sendOtpHandler = async () => {
                if (!validatePhoneNumber()) {
                    return;
                }
                const payload = {
                    transactionType: "verify mobile",
                    mobile: +`${countryData?.isd}${userData?.mobile}` ?? "",
                };
                const response = await postCall(SEND_OTP(), payload);
                if (response.msg !== "Success") {
                    if (response.errorCode === "TLC-905") {
                        setOtpSentErrorModal((prev) => ({
                            ...prev,
                            show: true,
                        }));
                    }
                    return;
                }
                setOtpSent({ sent: true, first: true, resend: true });
                setOtpState((prev) => ({
                    ...prev,
                    trId: response?.data?.transactionID,
                }));
            };
            const handlerId = setTimeout(() => {
                if (
                    userData.mobile.length >= countryData.minLength &&
                    !otpState.verified &&
                    !otpSent.first
                ) {
                    sendOtpHandler();
                }
            }, 1500);
            return () => {
                clearTimeout(handlerId);
            };
        }, [
            countryData?.isd,
            countryData.minLength,
            otpSent.first,
            otpState.verified,
            setOtpSentErrorModal,
            setOtpState,
            userData.mobile,
            validatePhoneNumber,
        ]);
        return (
            <section className="membership-user-info-section">
                <Row>
                    <Col xs={12} md={12}>
                        <h2>Personal Details</h2>
                    </Col>
                    <Col xs={3} md={2}>
                        <Form.Select
                            className={`${
                                userDataValid.salutation ? "" : "invalid-bottom"
                            } ${
                                userData.salutation.length === 0
                                    ? "select-placeholder"
                                    : ""
                            }`}
                            disabled={inputDisabled}
                            aria-label="salutation"
                            name="salutation"
                            value={userData.salutation}
                            onChange={userDataChangeHandler}
                        >
                            <option value="" disabled hidden>
                                Title
                            </option>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                        </Form.Select>
                    </Col>
                    <Col xs={9} md={4}>
                        <Form.Control
                            className={
                                userDataValid.fName ? "" : "invalid-bottom"
                            }
                            disabled={inputDisabled}
                            name="fName"
                            type="text"
                            placeholder="First name"
                            value={userData.fName}
                            onChange={userDataChangeHandler}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Control
                            className={
                                userDataValid.lName ? "" : "invalid-bottom"
                            }
                            disabled={inputDisabled}
                            name="lName"
                            type="text"
                            placeholder="Last name"
                            value={userData.lName}
                            onChange={userDataChangeHandler}
                        />
                    </Col>
                </Row>
                <Row className="phone-row">
                    <Col xs={3} md={2} className="membership-country-select">
                        <button
                            type="button"
                            className="country-select country-select-button w-100 d-flex justify-content-between align-items-center"
                            onClick={() => {
                                setCountrySelectShow(!countrySelectShow);
                            }}
                            disabled={inputDisabled}
                        >
                            <input
                                type="hidden"
                                name="00N0o00000NCGlK"
                                value={`+${countryData.isd}`}
                            />
                            <span className="country-select">
                                +{countryData.isd}
                            </span>
                            <img
                                alt="V"
                                className="country-select country-select-down-arrow"
                                src={downArrow}
                            />
                        </button>
                        <CountrySelectorDropdown
                            show={countrySelectShow}
                            selectedCountry={countryData.iso2}
                            onSelect={countryCodeSelectionHandler}
                        />
                    </Col>
                    <Col xs={9} md={4}>
                        <Form.Control
                            className={
                                userDataValid.mobile ? "" : "invalid-bottom"
                            }
                            maxLength={countryData.maxLength}
                            name="mobile"
                            type="tel"
                            placeholder="Mobile Number"
                            value={userData.mobile}
                            onChange={mobileChangeHandler}
                        />
                    </Col>
                    <Col xs={12} md={6} className="col-otp-div">
                        <div className="otp-div">
                            <div className="otp-label">Enter OTP</div>
                            <div className="otp-input-div">
                                <OTPInput
                                    OTPLength={4}
                                    otpType="number"
                                    value={otp}
                                    className={`otp-plugin ${
                                        otpState.inValid ? "invalid-otp" : ""
                                    }`}
                                    onChange={setOtp}
                                    disabled={otpState.verified}
                                />
                            </div>
                            {otpState.verified && (
                                <img src={verifiedIcon} alt="" />
                            )}
                        </div>
                        {userData.mobile.length !== 0 && !otpState.verified ? (
                            <>
                                <div className="resend-otp">
                                    <span className="placeholder-div invisible">
                                        Enter OTP
                                    </span>
                                    {otpSent.first ? (
                                        <div className="timer-div">
                                            <p
                                                className={`cp ${
                                                    otpSent.resend
                                                        ? "disable"
                                                        : "text"
                                                }`}
                                                onClick={resendOtpHandler}
                                            >
                                                Resend
                                            </p>
                                            <p className="timer">
                                                00:{formatTime()}
                                            </p>
                                        </div>
                                    ) : (
                                        <span className="invisible">Send</span>
                                    )}
                                </div>
                            </>
                        ) : (
                            <div className="resend-otp invisible">
                                <span>.</span>
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={5}>
                        <Form.Control
                            className={
                                userDataValid.email ? "" : "invalid-bottom"
                            }
                            disabled={inputDisabled}
                            name="email"
                            type="text"
                            placeholder="Email"
                            value={userData.email}
                            onChange={userDataChangeHandler}
                        />
                    </Col>
                </Row>
            </section>
        );
    }
);

export const GSTDetails = React.memo(
    ({
        showGst,
        setShowGst,
        setStep,
        otp,
        countryData,
        userData,
        validateUserDataInput,
        userGstData,
        setUserGstData,
        userGstDataValid,
        setUserGstDataValid,
        validateUserGstInput,
        promoCode,
        validatePromoCode,
        resetStepOne,
    }) => {
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const [memberDataMismatchModal, setMemberDataMismatchModal] = useState({
            modalId: "#member-data-error",
            show: false,
            content:
                "The Mobile number or the Email already exists in the system with different accounts.",
            btnText: "OK",
            icon: errorIcon,
        });
        const hideModal = () => {
            setMemberDataMismatchModal((prev) => ({ ...prev, show: false }));
        };
        const userGstChangeHandler = (e) => {
            let { name, value } = e.target;
            if (name === "pinCode") {
                const isNum = NUMBER_REGEX.test(value);
                if (!isNum || value.length > 6) return;
            }
            if (
                name === "cName" ||
                name === "address1" ||
                name === "address2"
            ) {
                if (value[0]) {
                    value = value[0].toUpperCase() + value.slice(1);
                }
            }
            if (name === "gstNum") {
                value = value.toUpperCase().trim();
                if (value.length === 15) {
                    const state = gstStateData.find(
                        (data) => data.code === value.slice(0, 2)
                    );
                    if (state) {
                        setUserGstData((prev) => ({
                            ...prev,
                            state: state.name,
                        }));
                    } else {
                        setUserGstData((prev) => ({ ...prev, state: "" }));
                    }
                }
                if (value.length > 15) {
                    return;
                }
            }
            setUserGstDataValid((prev) => ({ ...prev, [name]: true }));
            setUserGstData((prev) => ({ ...prev, id: "", [name]: value }));
        };
        const nextClickHandler = async () => {
            if (!promoCode.valid) {
                return;
            }
            const isUserDataValid = validateUserDataInput();
            const isUserGstValid = validateUserGstInput();
            if (promoCode.value.length !== 0 && !promoCode.applied) {
                const isPromoCodeValid = await validatePromoCode();
                if (!isPromoCodeValid) {
                    return;
                }
            }
            if (isUserDataValid && isUserGstValid) {
                const phone = `${countryData?.isd}${userData?.mobile}`;
                const userEmailMobileMatchResponse = await getCall(
                    VERIFY_MEMBER_DETAILS(phone, userData?.email)
                );
                if (userEmailMobileMatchResponse.msg === "FAIL") {
                    setMemberDataMismatchModal((prev) => ({
                        ...prev,
                        show: true,
                    }));
                } else {
                    const encryptedUserData = encryptDataFunction(userData);
                    sessionStorage.setItem("userData", encryptedUserData);
                    const encryptedCountrySelectData =
                        encryptDataFunction(countryData);
                    sessionStorage.setItem(
                        "c_data",
                        encryptedCountrySelectData
                    );
                    sessionStorage.setItem("otp", otp);
                    const encryptedUserGstData =
                        encryptDataFunction(userGstData);
                    if (showGst) {
                        sessionStorage.setItem(
                            "userGstData",
                            encryptedUserGstData
                        );
                    } else {
                        sessionStorage.removeItem("userGstData");
                    }
                    sessionStorage.setItem("step", "2");
                    setStep("2");
                }
            }
        };
        useEffect(() => {
            const validatePinCode = async () => {
                dispatch(setPageLoader(true));
                const response = await getCall(
                    VALIDATE_PINCODE(userGstData.pinCode)
                );
                dispatch(setPageLoader(false));
                if (response.msg !== "Success") {
                    return;
                }
                if (response.data[0].Status !== "Success") {
                    setUserGstDataValid((prev) => ({
                        ...prev,
                        pinCode: false,
                    }));
                    return;
                }
                if (
                    (response.data[0]?.PostOffice[0]?.State ?? "") !==
                    userGstData.state
                ) {
                    setUserGstDataValid((prev) => ({
                        ...prev,
                        pinCode: false,
                    }));
                } else {
                    setUserGstDataValid((prev) => ({ ...prev, pinCode: true }));
                }
            };
            if (userGstData.pinCode.length === 6) {
                validatePinCode();
            }
        }, [
            dispatch,
            setUserGstDataValid,
            userGstData.pinCode,
            userGstData.state,
        ]);
        return (
            <>
                <CustomModal
                    modalState={memberDataMismatchModal}
                    onClose={hideModal}
                />
                <section className="membership-user-gst-section">
                    <Row>
                        <Col xs={12} md={12}>
                            <h2>GST Details</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <Form.Check
                                type="checkbox"
                                label="I want to provide my GST Details (Optional)"
                                id="gst-check"
                                checked={showGst}
                                onChange={(e) => {
                                    setShowGst(e.target.checked);
                                }}
                            />
                        </Col>
                    </Row>
                    {showGst && (
                        <>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Control
                                        className={
                                            userGstDataValid.gstNum
                                                ? ""
                                                : "invalid-bottom"
                                        }
                                        value={userGstData.gstNum}
                                        onChange={userGstChangeHandler}
                                        name="gstNum"
                                        type="text"
                                        placeholder="GST Number"
                                    />
                                </Col>
                                <Col xs={12} md={6} className="mobile-row">
                                    <Form.Control
                                        className={
                                            userGstDataValid.cName
                                                ? ""
                                                : "invalid-bottom"
                                        }
                                        value={userGstData.cName}
                                        onChange={userGstChangeHandler}
                                        name="cName"
                                        type="text"
                                        placeholder="Company Name"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Control
                                        className={
                                            userGstDataValid.address1
                                                ? ""
                                                : "invalid-bottom"
                                        }
                                        value={userGstData.address1}
                                        onChange={userGstChangeHandler}
                                        name="address1"
                                        type="text"
                                        placeholder="Add 1"
                                    />
                                </Col>
                                <Col xs={12} md={6} className="mobile-row">
                                    <Form.Control
                                        className={
                                            userGstDataValid.address2
                                                ? ""
                                                : "invalid-bottom"
                                        }
                                        value={userGstData.address2}
                                        onChange={userGstChangeHandler}
                                        name="address2"
                                        type="text"
                                        placeholder="Add 2"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Select
                                        className="cd"
                                        value={userGstData.state}
                                        aria-label="State"
                                        name="state"
                                        disabled
                                    >
                                        <option value="" hidden>
                                            State
                                        </option>
                                        {gstStateData.map((data, i) => (
                                            <option key={i} value={data.name}>
                                                {data.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Form.Control
                                        className={
                                            userGstDataValid.pinCode
                                                ? ""
                                                : "invalid-bottom"
                                        }
                                        value={userGstData.pinCode}
                                        onChange={userGstChangeHandler}
                                        name="pinCode"
                                        type="text"
                                        placeholder="Pin Code"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form.Select
                                        className="cd"
                                        aria-label="Country"
                                        name="country"
                                        value={userGstData.country}
                                        disabled
                                    >
                                        <option value="India">India</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div className="step-1-btn-grp">
                        <button
                            className="back-btn rounded"
                            onClick={() => {
                                resetStepOne();
                                const property =
                                    sessionStorage.getItem("property");
                                if (property === "delhi") {
                                    navigate("/radisson-delhi");
                                } else if (property === "varanasi") {
                                    navigate("/radisson-varanasi");
                                }
                            }}
                        >
                            Back
                        </button>
                        <button
                            className="next-btn rounded"
                            onClick={nextClickHandler}
                        >
                            Next
                        </button>
                    </div>
                </section>
            </>
        );
    }
);
