import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import AppInfoSection from "../components/appInfoSection/AppInfoSection";
import OfferAndEventSection from "../components/offerAndEventSection/OfferAndEventSection";
import Footer from "../components/footer/Footer";
import MembershipPrivilegeSection from "../components/membershipPrivilegeSection/MembershipPrivilegeSection";
import ParticipatingHotelListingSection from "../components/participatingHotelListingSection/ParticipatingHotelListingSection";
import { getCall } from "../utils/AxiosInterseptor";
import { FETCH_MEMBERSHIP_PRICE } from "../utils/axiosUtil";
import { decryptDataFunction, encryptDataFunction } from "../utils/utils";
import homepageBanner from "../assets/homepage-banner.webp";
import homepageBannerMobile from "../assets/homepage-banner-mobile.webp";
import { defaultOfferCarousalData } from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";

const HomePage = () => {
    useEffect(() => {
        //TO RESET STEP ONE
        if (sessionStorage.getItem("userData")) {
            sessionStorage.removeItem("step");
            sessionStorage.removeItem("d_plan");
            sessionStorage.removeItem("otp");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("userGstData");
            sessionStorage.removeItem("flag");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("m_num");
            sessionStorage.removeItem("t_type");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("l_id");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("z_flag");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("gate_data");
            sessionStorage.removeItem("lnk_flg");
        }
        sessionStorage.removeItem("plan");
        sessionStorage.removeItem("property");
    }, []);
    useEffect(() => {
        let flag = false;
        const fetchMemberPriceData = async () => {
            flag = true;
            const savedMemberPriceData = decryptDataFunction(
                sessionStorage.getItem("pln_mstr_data")
            );
            if (savedMemberPriceData) {
                return;
            }
            const response = await getCall(FETCH_MEMBERSHIP_PRICE());
            if (response.msg !== "Success") {
                return;
            }
            const citiesArray = response.data?.countries[0]?.cities;
            const delhi = citiesArray.find((city) => city.cityid === "274");
            const jalandhar = citiesArray.find(
                (city) => city.cityid === "1144"
            );
            const varanasi = citiesArray.find((city) => city.cityid === "1683");
            const delhiOrderedMembershipPriceData =
                delhi?.properties[0]?.levels?.sort((a, b) => {
                    const order = {
                        Copper: 1,
                        Silver: 2,
                        Gold: 3,
                        Platinum: 4,
                    };
                    return order[a.levelName] - order[b.levelName];
                });
            const varanasiOrderedMembershipPriceData =
                varanasi?.properties[0]?.levels?.sort((a, b) => {
                    const order = {
                        Copper: 1,
                        Silver: 2,
                        Gold: 3,
                        Platinum: 4,
                    };
                    return order[a.levelName] - order[b.levelName];
                });
            let masterPriceData = {
                delhi: delhiOrderedMembershipPriceData ?? [],
                jalandhar: jalandhar?.properties[0]?.levels ?? [],
                varanasi: varanasiOrderedMembershipPriceData ?? [],
            };
            const encryptedMembershipPriceData =
                encryptDataFunction(masterPriceData);
            sessionStorage.setItem(
                "pln_mstr_data",
                encryptedMembershipPriceData
            );
        };
        if (!flag) {
            fetchMemberPriceData();
        }
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Join the Prestige Club membership and avail exclusive benefits on dining, accommodation and spa at participating Radisson hotels in India."
                />
                <title>
                    Prestige Club | Membership of Participating Radisson Hotels
                </title>
            </Helmet>
            <Header />
            <HeroSection
                src={homepageBanner}
                mobileSrc={homepageBannerMobile}
                showForm={true}
                alt="Prestige Club home page banner"
            />
            <MembershipPrivilegeSection />
            <ParticipatingHotelListingSection />
            <AppInfoSection />
            <OfferAndEventSection carousalData={defaultOfferCarousalData} />
            <Footer />
        </>
    );
};

export default HomePage;
