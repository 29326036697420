import React, { Suspense, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { AxiosInterceptor } from "./utils/AxiosInterseptor";
import HomePage from "./pages/HomePage";
import ContactUsPage from "./pages/ContactUsPage";
import OverviewPage from "./pages/OverviewPage";
import BuyMemberBenefitPage from "./pages/BuyMemberBenefitPage";
import ThankyouPage from "./pages/ThankyouPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentFailPage from "./pages/PaymentFailPage";
import { PageLoader, PaymentLoader } from "./components/pageLoader/PageLoader";
import DelhiHomePage from "./pages/DelhiHomePage";
import JalandharHomePage from "./pages/JalandharHomePage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import AppTermsAndConditionsPage from "./pages/AppTermsAndConditionsPage";
import AcceptCookieModal from "./components/acceptCookieModal/AcceptCookieModal";
import DisclaimerPage from "./pages/DisclaimerPage";
import PaymentGatewayPage from "./pages/PaymentGatewayPage";
import WebsiteUsePage from "./pages/WebsiteUsePage";
import { Helmet } from "react-helmet";
import TagManager from "react-gtm-module";
import ReactGA from "react-ga4";
import RedeemBenefitOtpPage from "./pages/RedeemBenefitOtpPage";
import VaranasiHomePage from "./pages/VaranasiHomePage";

const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTM_ID,
};
const env = process.env.REACT_APP_ENV;

function App() {
    const location = useLocation();
    useEffect(() => {
        if (env === "staging") {
            TagManager.initialize(tagManagerArgs);
            ReactGA.initialize(process.env.REACT_APP_GA_ID);
        }
    }, []);
    useEffect(() => {
        if (env === "staging") {
            TagManager.dataLayer({
                dataLayer: {
                    event: "page_view",
                    pagePath: window.location.href,
                },
            });
            ReactGA.send({ hitType: "pageview", page: window.location.href });
        }
    }, [location]);
    return (
        <>
            <Helmet>
                {/* To De-index for UAT */}
                {env === "staging" && (
                    <meta name="robots" content="noindex, nofollow" />
                )}
            </Helmet>
            <AxiosInterceptor>
                <Suspense fallback={<PageLoader suspenseLoading={true} />}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route
                            path="/radisson-delhi"
                            element={<DelhiHomePage />}
                        />
                        <Route
                            path="/radisson-jalandhar"
                            element={<JalandharHomePage />}
                        />
                        <Route
                            path="/radisson-varanasi"
                            element={<VaranasiHomePage />}
                        />
                        <Route path="/contact-us" element={<ContactUsPage />} />
                        <Route
                            path="/cards-and-certificates"
                            element={<TermsAndConditionsPage />}
                        />
                        <Route
                            path="/app-terms-and-conditions"
                            element={<AppTermsAndConditionsPage />}
                        />
                        <Route
                            path="/disclaimer"
                            element={<DisclaimerPage />}
                        />
                        <Route
                            path="/website-use"
                            element={<WebsiteUsePage />}
                        />
                        <Route
                            path="/payment-gateway"
                            element={<PaymentGatewayPage />}
                        />
                        <Route
                            path="/overview/:offerKey"
                            element={<OverviewPage />}
                        />
                        <Route
                            path="/membership-details"
                            element={<BuyMemberBenefitPage />}
                        />
                        <Route path="/thankyou" element={<ThankyouPage />} />
                        <Route
                            path="/membership-details/success"
                            element={<PaymentSuccessPage />}
                        />
                        <Route
                            path="/membership-details/fail"
                            element={<PaymentFailPage />}
                        />
                        <Route
                            path="/redeem-membership"
                            element={<RedeemBenefitOtpPage />}
                        />
                        <Route
                            path="*"
                            element={<Navigate to={"/"} replace />}
                        />
                    </Routes>
                    <PageLoader />
                    <PaymentLoader />
                    <AcceptCookieModal />
                </Suspense>
            </AxiosInterceptor>
        </>
    );
}

export default App;
