import React, { useEffect } from "react";
import Header from "../components/header/Header";
import HeroSection from "../components/heroSection/HeroSection";
import HotelInfoSection from "../components/hotelInfoSection/HotelInfoSection";
import AppInfoSection from "../components/appInfoSection/AppInfoSection";
import MemberBenefitSection from "../components/memberBenefitSection/MemberBenefitSection";
import OfferAndEventSection from "../components/offerAndEventSection/OfferAndEventSection";
import Footer from "../components/footer/Footer";
import hotelImage from "../assets/radisson-varanasi-side-image.webp";
import {
    varanasiAnnualBenefitData,
    varanasiOneTimeBenefitData,
} from "../utils/tableData";
import { varanasiOfferCarousalData } from "../utils/offerAndEventsData";
import { Helmet } from "react-helmet";
import homepageBanner from "../assets/radisson-varanasi-banner.webp";
import homepageBannerMobile from "../assets/radisson-varanasi-banner-mobile.webp";

const VaranasiHomePage = () => {
    useEffect(() => {
        // Reset Scroll to Top
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        //TO RESET STEP ONE
        if (sessionStorage.getItem("userData")) {
            sessionStorage.removeItem("step");
            sessionStorage.removeItem("d_plan");
            sessionStorage.removeItem("otp");
            sessionStorage.removeItem("userData");
            sessionStorage.removeItem("userGstData");
            sessionStorage.removeItem("flag");
            sessionStorage.removeItem("memberId");
            sessionStorage.removeItem("m_num");
            sessionStorage.removeItem("sfId");
            sessionStorage.removeItem("c_data");
            sessionStorage.removeItem("l_id");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("z_flag");
            sessionStorage.removeItem("update");
            sessionStorage.removeItem("gate_data");
            sessionStorage.removeItem("lnk_flg");
        }
    }, []);
    const varanasiPropertyTableData = [
        {
            clone: true,
            tableHeading: "Annual Benefits",
            tableData: varanasiAnnualBenefitData,
            tableScrollId: "varanasi-annual-table-scroll",
            tableId: "varanasi-annual-main-table",
        },
        {
            clone: true,
            tableHeading: "One Time Use Benefits",
            tableHead: "Certificates",
            tableData: varanasiOneTimeBenefitData,
            tableScrollId: "varanasi-oneTime-table-scroll",
            tableId: "varanasi-oneTime-main-table",
        },
    ];
    useEffect(() => {
        sessionStorage.setItem("property", "varanasi");
    }, []);
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Enrol as a member of Prestige Club at Radisson Hotel Varanasi and enjoy exclusive benefits and special offers on dining, stay and more, all year round."
                />
                <title>Membership of Radisson Hotel Varanasi</title>
            </Helmet>
            <Header />
            <HeroSection
                src={homepageBanner}
                mobileSrc={homepageBannerMobile}
                showForm={true}
                alt="Prestige Club Radisson Hotel Varanasi banner"
            />
            <HotelInfoSection
                content="Join the Prestige Club membership at Radisson Hotel Varanasi and enjoy a unique culinary experience. Located in the spiritual capital of India, the hotel is near Kashi Vishwanath Temple and Main Ghats. Savor Eastern and Western dishes at eastWEST, kababs at The Great Kabab Factory, and drinks at Oakwood Bar."
                heading="Radisson Hotel Varanasi"
                imgSrc={hotelImage}
                imgAlt="Prestige Club Radisson Hotel Varanasi side image"
            />
            <AppInfoSection />
            <MemberBenefitSection
                property="varanasi"
                propertyTableData={varanasiPropertyTableData}
                buyFlag={true}
            />
            <OfferAndEventSection carousalData={varanasiOfferCarousalData} />
            <Footer />
        </>
    );
};

export default VaranasiHomePage;
